import React from "react"

import { FormikErrors } from "formik"

interface ValidationNoticeProps {
  error: string | FormikErrors<any>
}

const ValidationNotice: React.FC<ValidationNoticeProps> = ({ error = "" }) => {
  return (
    <div className="validationNotice danger">
      <span>{error}</span>
    </div>
  )
}

export default ValidationNotice
