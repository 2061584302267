import React from "react"
import { Text, Button, Callout } from "@blueprintjs/core"
import { Formik, Form, FormikProps } from "formik"
import TextField from "../../fields/TextField/TextField"
import * as Yup from "yup"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import ReactHtmlParser from "react-html-parser"
import ClientServices from "@services/ClientServices"

// https://us18.admin.mailchimp.com/lists/
// https://mailchimp.com/help/host-your-own-signup-forms/

interface NewsletterFormProps {
  ref?: React.Ref<any>
  className?: string
  onClick?: (e: MouseEvent) => void
}

interface NewsletterValues {
  email: string
}

const url =
  "//climatescore.us18.list-manage.com/subscribe/post?u=3549af61f094eeeb5fd58853c&id=c6f5000767"

enum SubmissionStates {
  FRESH = "fresh",
  LOADING = "loading",
  DONE = "done",
}

const NewsletterForm: React.FC<NewsletterFormProps> = () => {
  const [submissionError, setSubmissionError] = React.useState(null)
  const clientServices = new ClientServices()
  const [submissionState, setSubmissionState] = React.useState(
    SubmissionStates.FRESH
  ) // fresh, loading, done

  const NewsletterSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .min(4, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
  })

  const innerForm = (subscribe, status, message) => {
    let errorNotice = <></>
    if (submissionError || status === "error") {
      errorNotice = (
        <Callout className="whiteBgNotice" title="Attention" intent="danger">
          {ReactHtmlParser(submissionError)}
          {ReactHtmlParser(message)}
        </Callout>
      )
    }
    let thankNotice = <></>
    if (SubmissionStates.DONE || status === "success") {
      thankNotice = (
        <Callout className="whiteBgNotice" title="Thank you" intent="success">
          <Text tagName="p" className="success-note">
            We'll keep you updated on all things ClimateCheck®.
          </Text>
        </Callout>
      )
    }
    switch (submissionState) {
      case SubmissionStates.FRESH || SubmissionStates.LOADING:
        return (
          <>
            {errorNotice}
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={NewsletterSchema}
              onSubmit={(values: NewsletterValues, actions) => {
                setSubmissionState(SubmissionStates.LOADING)

                clientServices.logs.event(
                  "Subscribe form submission attempt",
                  values
                )
                subscribe({ EMAIL: values.email })
              }}
            >
              {(formikBag: FormikProps<NewsletterValues>) => {
                return (
                  <Form>
                    <TextField
                      label=""
                      fieldName="email"
                      fieldPlaceholder="Enter your email address..."
                      fieldType="email"
                    />
                    <Button
                      type="submit"
                      disabled={formikBag.isSubmitting || status === "sending"}
                      loading={formikBag.isSubmitting || status === "sending"}
                    >
                      Sign Up
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </>
        )
      default:
        return (
          <>
            {errorNotice}
            {thankNotice}
          </>
        )
    }
  }
  return (
    <>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <div>{innerForm(subscribe, status, message)}</div>
        )}
      />
    </>
  )
}

export default NewsletterForm
