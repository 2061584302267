import React from "react"
import { Link } from "gatsby"
import ClimateCheckLogo from "../../../ui/ClimateCheckLogo/ClimateCheckLogo"
import TwitterIcon from "@images/icons/icon-social-twitter-black.svg"
import FacebookIcon from "@images/icons/icon-social-facebook-black.svg"
import LinkedInIcon from "@images/icons/icon-social-linkedin-black.svg"
import InstagramIcon from "@images/icons/icon-social-instagram-black.svg"
import NewsletterForm from "../../forms/NewsletterForm/NewsletterForm"
import ImageWidget from "./ImageWidget"
import { states } from "../../../utils/customDate"

const CorporativeFooter = () => {
  const navigateTo = (url: string) => window.open(url)
  return (
    <section className="corporative-footer">
      <div className="corporative-footer-contact">
        <p>
          Questions or feedback? Contact us at{" "}
          <a href="mailto:info@climatecheck.com" rel="noopener">
            info@climatecheck.com
          </a>
        </p>
      </div>

      <div className="corporative-footer-site">
        <>
          <div className="corporative-footer-site-links">
            <TwitterIcon
              onClick={() => navigateTo("https://twitter.com/MyClimateCheck")}
            />
            <FacebookIcon
              onClick={() =>
                navigateTo("https://www.facebook.com/myclimatecheck/")
              }
            />

            <LinkedInIcon
              onClick={() =>
                navigateTo("https://www.linkedin.com/company/myclimatecheck")
              }
            />
            <InstagramIcon
              onClick={() =>
                navigateTo("https://www.instagram.com/myclimatecheck/")
              }
            />
          </div>
          <ClimateCheckLogo />
          <div className="corporative-footer-links">
            <Link to="/solutions">Solutions</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/about-us#our-team">Team</Link>
            <Link to="/risks">Risks</Link>
            <Link to="/our-methodologies">Methodologies</Link>
          </div>
          <div className="corporative-footer-perks">
            <div className="corporative-footer-perks-brokers">
              <p className="corporative-footer-heading">
                Free Widget for Brokers
              </p>
              <p>
                Show climate related risks for any US property directly on your
                website, with data powered by ClimateCheck®.
              </p>
              <p>
                Contact us at{" "}
                <a
                  href="mailto:info@climatecheck.com?subject=Get the ClimateCheck Widget"
                  rel="noopener"
                >
                  info@climatecheck.com
                </a>{" "}
                to get this widget.
              </p>
              <ImageWidget />
            </div>
            <div className="corporative-footer-perks-newsletter">
              <p className="corporative-footer-heading">Newsletter Signup</p>
              <p>
                Get the latest perspective, insights, and updates from
                ClimateCheck® straight to your inbox.
              </p>
              <p>All emails will adhere to our privacy policy.</p>
              <NewsletterForm />
            </div>
          </div>
        </>

        <div className="corporative-footer-container">
          <div className="corporative-footer-container-title">
            <p>Search for climate change risks by state</p>
          </div>
          <div className="corporative-footer-container-list-link">
            <div className="corporative-footer-container-col">
              {states.map((state, index) => {
                return (
                  index < 15 && (
                    <div
                      key={index}
                      className="corpotative-footer-container-list"
                    >
                      <Link
                        className="corporative-footer-container-col-link"
                        to={`/${state
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                      >
                        {state}
                      </Link>
                    </div>
                  )
                )
              })}
            </div>
            <div className="corporative-footer-container-col">
              {states.map((state, index) => {
                return (
                  index > 14 &&
                  index < 31 && (
                    <div
                      key={index}
                      className="corpotative-footer-container-list"
                    >
                      <Link
                        className="corporative-footer-container-col-link"
                        to={`/${state
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                      >
                        {state}
                      </Link>
                    </div>
                  )
                )
              })}
            </div>
            <div className="corporative-footer-container-col">
              {states.map((state, index) => {
                return (
                  index > 30 &&
                  index < 47 && (
                    <div
                      key={index}
                      className="corpotative-footer-container-list"
                    >
                      <Link
                        className="corporative-footer-container-col-link"
                        to={`/${state
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                      >
                        {state}
                      </Link>
                    </div>
                  )
                )
              })}
            </div>
          </div>
        </div>
        <div className="corporative-footer-info">
          <div className="corporative-footer-info-terms-policy">
            <Link to="/terms-of-service">Terms &amp; Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>

          <p>
            Copyright © {new Date().getFullYear()} ClimateCheck®, Inc. All
            rights reserved.
          </p>
        </div>
      </div>
    </section>
  )
}
export default CorporativeFooter
