import React from "react"
import { StaticQuery, graphql } from "gatsby"
//import Image from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageWidget = () => {
  return (
    <StaticQuery
      query={graphql`
        query WidgetQuery {
          imageWidget: file(relativePath: { eq: "img-min/footer-widget.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF] quality: 90)
            }
          }
        }
      `}
      render={data => (
        <div className="widget-container">
          <GatsbyImage image={data.imageWidget.childImageSharp.fluid} alt="widget" />
        </div>
      )}
    />
  )
}
export default ImageWidget
