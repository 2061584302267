import React from "react"

import { Field, FieldProps } from "formik"
import { FormGroup, InputGroup } from "@blueprintjs/core"
import ValidationNotice from "../ValidationNotice/ValidationNotice"
import InputMask from "react-input-mask"

export enum TextFieldMasks {
  NONE = "none",
  PHONE = "phone",
  CCNUMBER = "creditCardNumber",
}
interface TextFieldProps {
  ref?: React.Ref<any>
  className?: string
  onClick?: (e: MouseEvent) => void
  helperText?: string
  label?: string
  fieldName: string
  fieldInfo?: string
  fieldPlaceholder?: string
  fieldType?: string
  fieldMask?: TextFieldMasks
}

const TextField: React.FC<TextFieldProps> = ({
  ref = null,
  className = "",
  onClick = e => console.info("Click"),
  helperText = null,
  label = "",
  fieldName = "",
  fieldInfo = "(required)",
  fieldPlaceholder = "",
  fieldType = "text",
  fieldMask = TextFieldMasks.NONE,
}) => {
  let mask = ""
  switch (fieldMask) {
    case TextFieldMasks.PHONE:
      mask = "999-999-9999"
      break
    case TextFieldMasks.CCNUMBER:
      mask = "9999-9999-9999-9999"
      break
  }

  return (
    <Field ref={ref} name={fieldName}>
      {({ field, form, meta }: FieldProps<any>) => (
        <FormGroup
          helperText={helperText}
          label={label}
          labelFor={fieldName}
          labelInfo={fieldInfo}
        >
          {/* Does not properly integrate with validation */}
          {fieldMask !== TextFieldMasks.NONE ? (
            <InputMask
              mask={mask}
              value={field.value}
              // onChange={field.onChange}
              onBlur={field.onBlur}
              {...field}
              onChange={e => {
                const val = e.currentTarget.value
                let newVal = val
                form.setFieldValue(fieldName, newVal)
              }}
            >
              {inputProps => {
                return (
                  <InputGroup
                    id={fieldName}
                    className={className}
                    placeholder={fieldPlaceholder}
                    type={fieldType}
                    {...inputProps}
                  />
                )
              }}
            </InputMask>
          ) : (
            <InputGroup
              id={fieldName}
              className={className}
              placeholder={fieldPlaceholder}
              type={fieldType}
              {...field}
              onChange={e => {
                const val = e.currentTarget.value
                let newVal = val

                form.setFieldValue(fieldName, newVal)
              }}
            />
          )}

          {form.touched[fieldName] && form.errors[fieldName] ? (
            <ValidationNotice error={form.errors[fieldName] as string} />
          ) : (
            <></>
          )}
        </FormGroup>
      )}
    </Field>
  )
}

export default TextField
